import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeaderSection from '../../HeaderSection/HeaderSection';
import LightIcon from '../../../icons/light.inline.svg';

import './Simplifying.scss';

const renderCompareRows = row => (
  <div className="simplifying__compare-row" key={row.left}>
    <div className="simplifying__column simplifying__column--left">
      <p>{row.left}</p>
    </div>
    <div className="simplifying__column simplifying__column--vs">
      <div className="simplifying__column-hr" />
    </div>
    <div className="simplifying__column simplifying__column--right">
      <p>{row.right}</p>
    </div>
  </div>
);

const Simplifying = () => {
  const {
    whyEdwinJson: { simplifying },
  } = useStaticQuery(graphql`
    {
      whyEdwinJson {
        simplifying {
          left
          right
        }
      }
    }
  `);

  return (
    <div className="simplifying">
      <div className="container">
        <HeaderSection
          icon={<LightIcon />}
          subTitle="Human Behavior"
          title="Edwin provides an easy, practical, and habit-forming way to help organizations change not just what they <strong>know</strong> about cybersecurity, but what they <strong>do</strong>."
        />
      </div>
      <div className="container simplifying__container">
        <div className="simplifying__compare">
          <div className="simplifying__compare-row simplifying__compare-row--title">
            <div className="simplifying__column simplifying__column--left">
              <h2>Competitors</h2>
            </div>
            <div className="simplifying__column simplifying__column--vs">
              <h3>VS.</h3>
            </div>
            <div className="simplifying__column simplifying__column--right">
              <h2>Edwin</h2>
            </div>
          </div>
          {simplifying.map(renderCompareRows)}
        </div>
      </div>
    </div>
  );
};

export default Simplifying;
