import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeaderSection from '../../HeaderSection/HeaderSection';
import EveryoneIcon from '../../../icons/everyone.inline.svg';
import HumanBehaviorIcon from './HumanBehaviorIcon';

import './HumanBehavior.scss';

const renderHumanBehaviorIcons = element => (
  <div key={element.title} className="human-behavior__element">
    <HumanBehaviorIcon
      shapePath={element.shapePath}
      className="human-behavior__shape"
    />
    <img
      alt={element.title}
      src={element.icon.publicURL}
      className="human-behavior__icon"
    />
    <h3 className="human-behavior__title">{element.title}</h3>
    <p className="human-behavior__description">{element.description}</p>
  </div>
);

const HumanBehavior = () => {
  const {
    whyEdwinJson: { humanBehavior },
  } = useStaticQuery(graphql`
    {
      whyEdwinJson {
        humanBehavior {
          title
          description
          shapePath
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <div className="human-behavior">
      <div className="container">
        <HeaderSection
          icon={<EveryoneIcon />}
          subTitle="Human Behavior"
          title="Edwin reduces risks."
          description="Most organizations don’t understand their security risks. Breaches, vulnerabilities, and hacks are growing exponentially. Current tools and awareness training are not succeeding in addressing the most important element: human behavior."
        />
        <div className="human-behavior__wrapper">
          {humanBehavior.map(renderHumanBehaviorIcons)}
        </div>
      </div>
    </div>
  );
};

export default HumanBehavior;
