import React from 'react';

import Button from '../../shared/Button/Button';
import './IntroSection.scss';

const IntroSection = () => {
  return (
    <div className="why-edwin-intro">
      <div className="container">
        <div className="why-edwin-intro__background">
          <h1 className="why-edwin-intro__header">
            Edwin helps people develop good security habits.
          </h1>
        </div>
        <div className="why-edwin-intro__content">
          <div className="why-edwin-intro__knowledge">
            <h4>DID YOU KNOW..</h4>
            <p>
              Most security breaches are caused by human behavior and the
              average breach costs $3.9M.
            </p>
            <p>
              Most people don’t even know what good habits are, and having them
              be second nature rarely happens at even the most secure company.
            </p>
          </div>
          <p className="why-edwin-intro__description">
            Edwin helps people develop good security habits by transforming them
            into simple, interactive missions. These missions use proven
            learning and behavior design techniques to create habits that will
            keep individuals and their organizations secure. And, in turn,
            individuals can use the skills they learn to keep their family and
            home secure.
          </p>
          <Button
            fluid={false}
            type="secondary"
            className="why-edwin-intro__book-demo"
            to="/book-demo"
          >
            Book a Demo
          </Button>
          <Button fluid={false} to="/our-product">
            Learn More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
