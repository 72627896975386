import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Header from '../components/Header/Header';
import IntroSection from '../components/WhyEdwin/IntroSection/IntroSection';
import FirstQuote from '../components/WhyEdwin/FirstQuote/FirstQuote';
import HumanBehavior from '../components/WhyEdwin/HumanBehavior/HumanBehavior';
import HumanFeature from '../components/WhyEdwin/HumanFeature/HumanFeature';
import Simplifying from '../components/WhyEdwin/Simplifying/Simplifying';
import SecondQuote from '../components/WhyEdwin/SecondQuote/SecondQuote';
import Customers from '../components/shared/Customers/Customers';
import FinalCta from '../components/shared/FinalCta/FinalCta';

const WhyEdwin = () => (
  <Layout>
    <Header demoButtonNavigation="/book-demo" />
    <Seo
      title="Edwin - Cybersecurity designed for humans"
      description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
    />
    <IntroSection />
    <FirstQuote />
    <HumanBehavior />
    <HumanFeature />
    <Simplifying />
    <SecondQuote />
    <Customers />
    <FinalCta />
  </Layout>
);

export default WhyEdwin;
