import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon1 from '../../../icons/ilustration_08.inline.svg';
import shapePaths from '../../../utils/shapePaths';

const FirstQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "aboutUs/team/tim.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <QuoteSection
      type="left"
      content={
        <span>
          “We specifically designed our missions to help develop habits that
          apply not only to cybersecurity, but for creating changes across all
          areas of life.”
        </span>
      }
      shapePath={shapePaths[8]}
      author={{
        name: 'Tim Gifford',
        title: 'Edwin Learning Design Lead',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon1 />}
    />
  );
};

export default FirstQuote;
