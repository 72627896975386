import React from 'react';
import PropTypes from 'prop-types';

const HumanBehaviorIcon = ({ shapePath, className }) => (
  <svg
    width="268"
    height="250"
    viewBox="0 0 268 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity="0.1">
      <path d={shapePath} fill="url(#linearGradient)" />
    </g>
  </svg>
);

HumanBehaviorIcon.defaultProps = {
  className: '',
};

HumanBehaviorIcon.propTypes = {
  shapePath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HumanBehaviorIcon;
