import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon1 from '../../../icons/ilustration_04.inline.svg';
import shapePaths from '../../../utils/shapePaths';

const FirstQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "aboutUs/team/jo.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <QuoteSection
      type="left"
      content={
        <span>
          “Behavior design and learning experience underpins every single Edwin
          mission. Each mission is a powerful stand-alone tool, but collectively
          they have incredible impact.”
        </span>
      }
      shapePath={shapePaths[8]}
      author={{
        name: 'Jo Gifford',
        title: 'Edwin Conversation Design Lead',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon1 />}
    />
  );
};

export default FirstQuote;
